@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0px;
}

body {
  background-color: #242529;
}

body,
p,
a {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: #fff;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6 {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: #fff;
}

p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #fff;
}

ul,
li,
a {
  margin: 0px;
  padding: 0px;
  list-style: none;
  text-decoration: none;
  font-family: "Montserrat Alternates", sans-serif;
}

.btn-primary {
  background-color: #35353d;
  display: inline-block;
  color: #fff;
  padding: 10px 30px;
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  border: none;
}

.btn-secondary {
  background-color: #7F8287;
  display: inline-block;
  color: #fff;
  padding: 10px 30px;
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  border: none;
}

.box-white {
  padding: 15px;
  border-radius: 10px;
}

.section-heading {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
  position: relative;
}
.section-heading::after {
  content: "";
  display: block;
  width: 90px;
  height: 1px;
  /* Adjust the height of the underline */
  background-color: currentColor;
  /* Uses the current text color */
  position: absolute;
  bottom: -5px;
  /* Adjust the space between text and underline */
  left: 0;
  /* Aligns the underline with the start of the heading */
}

.overflow-y {
  overflow-y: scroll;
  /* Custom scrollbar styles */
}
.overflow-y::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}
.overflow-y::-webkit-scrollbar-track {
  background: #353537;
  /* Background of the scrollbar track */
  border-radius: 10px;
  /* Rounded corners for the track */
}
.overflow-y::-webkit-scrollbar-thumb {
  background-color: #555;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners for the thumb */
  border: 2px solid #2b2b2b;
  /* Adds space around the thumb */
}
.overflow-y::-webkit-scrollbar-thumb:hover {
  background-color: #777;
  /* Color when hovering over the scrollbar thumb */
}

.page-overflow {
  height: calc(100vh - 20px);
}

table {
  width: 100%;
  border-collapse: collapse;
  /* Removes the default spacing between table cells */
  margin: 15px 0px;
  /* Add a border to the entire table */
}
table thead {
  background-color: #333;
  /* Dark background for the table header */
  color: #fff;
  /* White text for the header */
}
table thead th {
  padding: 10px;
  /* Padding inside each header cell */
  text-align: left;
  /* Align text to the left */
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  /* Uppercase header text */
}
table tbody tr:nth-child(even) {
  background-color: #262626;
  /* Light gray background for even rows */
}
table tbody tr:hover {
  background-color: #262626;
  /* Slightly darker background on row hover */
}
table tbody tr td {
  padding: 10px;
  /* Padding inside each table cell */
  font-size: 12px;
  border-bottom: 1px solid #595959;
  /* Light gray border between rows */
}
table th,
table td {
  border: 1px solid #595959;
}

.ticket-prices-page form {
  margin-top: 30px;
  width: 60%;
  background-color: #13aac7;
  padding: 40px;
  border-radius: 30px;
}
.ticket-prices-page form .form-group {
  width: 100%;
  display: flex;
  gap: 30px;
}
.ticket-prices-page form .form-group div {
  width: 50%;
}
.ticket-prices-page form .form-group div label {
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat Alternates", sans-serif;
}
.ticket-prices-page form .form-group div input {
  width: 100%;
  padding: 7px 10px;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: #353537;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
}
.ticket-prices-page form button {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 20px;
  color: #262626;
  background-color: #dcdcdc;
  border: 2px solid #353537;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  letter-spacing: 1px;
  margin-top: 30px;
}
.ticket-prices-page form button:hover {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.App {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 20px);
  overflow: hidden;
  padding: 10px;
}
.App .sidebar {
  width: 12%;
}
.App hr {
  width: 0.3px;
  height: 100%;
}
.App .content {
  width: 83%;
}

.sidebar .logo {
  width: 100%;
}
.sidebar .logo img {
  width: 100%;
  border-radius: 10px;
}
.sidebar nav {
  width: 100%;
  margin-top: 30px;
}
.sidebar nav ul li a {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 3;
  transition: 0.3s ease-in-out;
  padding: 10px 15px;
}
.sidebar nav ul li:hover a,
.sidebar nav ul li .active a {
  padding: 10px 15px;
  background-color: #353537;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), 4px 0px 4px rgba(0, 0, 0, 0.1);
}
.sidebar .logout button {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  background-color: #13aac7;
  border: 2px solid #13aac7;
  color: #fff;
  font-family: "Montserrat Alternates", sans-serif;
  transition: 0.3s ease-in-out;
}
.sidebar .logout button:hover {
  background-color: transparent;
  color: #13aac7;
}

a.active-link {
  padding: 10px 25px;
  background-color: #353537;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), 4px 0px 4px rgba(0, 0, 0, 0.1);
}

.dashboard {
  height: 100%;
}
.dashboard .main-content {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.dashboard .main-content .dashboard-two-box {
  height: 100%;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard .main-content .dashboard-two-box .dashboard-two-box-inner {
  display: flex;
  justify-content: space-between;
}
.dashboard .main-content .dashboard-two-box .dashboard-two-box-inner .item {
  width: 42%;
  background-color: #353537;
}
.dashboard .main-content .dashboard-two-box .dashboard-two-box-inner .item .ticket-prices P {
  font-size: 18px;
  margin-bottom: 15px;
}
.dashboard .main-content .dashboard-two-box .dashboard-two-box-inner .item .ticket-prices button {
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #dcdcdc;
  border: 2px solid #353537;
  font-weight: 500;
  transition: 0.3s ease-in-out;
}
.dashboard .main-content .dashboard-two-box .dashboard-two-box-inner .item .ticket-prices button:hover {
  color: #dcdcdc;
  border: 2px solid #dcdcdc;
  background-color: transparent;
  scale: 1.03;
}
.dashboard .main-content .dashboard-two-box .dashboard-two-box-inner .item .warning-box {
  height: 150px;
}
.dashboard .main-content .dashboard-two-box .dashboard-two-box-inner .item .warning-box .warning-block {
  background-color: brown;
  margin: 5px 0px;
  padding: 5px;
  border-radius: 10px;
  margin-right: 10px;
}
.dashboard .main-content .dashboard-two-box .ticketing-form {
  background-color: #13aac7;
}
.dashboard .main-content .dashboard-two-box .ticketing-form h2 {
  color: #262626;
}
.dashboard .main-content .dashboard-two-box .ticketing-form form {
  width: 100%;
}
.dashboard .main-content .dashboard-two-box .ticketing-form form .form-group {
  width: 100%;
  display: flex;
  margin: 10px 0px;
  gap: 20px;
}
.dashboard .main-content .dashboard-two-box .ticketing-form form .form-group div {
  width: 100%;
}
.dashboard .main-content .dashboard-two-box .ticketing-form form .form-group div label {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  font-family: "Inter", sans-serif;
  color: #262626;
}
.dashboard .main-content .dashboard-two-box .ticketing-form form .form-group div input {
  width: 100%;
  padding: 7px 10px;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: #353537;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
}
.dashboard .main-content .dashboard-two-box .ticketing-form form .form-group div select {
  width: 100%;
  padding: 7px 10px;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: #353537;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
}
.dashboard .main-content .dashboard-two-box .ticketing-form form .total-price {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  margin: 15px 0px;
}
.dashboard .main-content .dashboard-two-box .ticketing-form form button {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 20px;
  color: #262626;
  background-color: #dcdcdc;
  border: 2px solid #353537;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  letter-spacing: 1px;
}
.dashboard .main-content .dashboard-two-box .ticketing-form form button:hover {
  background-color: transparent;
  color: #353537;
  scale: 1.03;
}
.dashboard .main-content .dashboard-two-box .ticketing-form .available {
  font-size: 16px;
  font-weight: 400;
  text-align: right;
  color: #262626;
}
.dashboard .main-content .dashboard-two-box .two-box-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}
.dashboard .main-content .dashboard-two-box .two-box-right .active-sessions {
  height: 48%;
  background-color: #353537;
}
.dashboard .main-content .dashboard-two-box .two-box-right .sessions-ending-soon {
  height: 48%;
  background-color: #353537;
}

.login-form {
  height: calc(100vh - 20px);
  width: calc(100vw - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0px 40px;
}
.login-form::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.925);
  /* Dark overlay with 50% opacity */
  z-index: 1;
  border-radius: 50px;
  /* Ensure the overlay is below the content */
}
.login-form .login-content {
  width: 55%;
  z-index: 2;
}
.login-form .login-content .login-heading {
  font-size: 52px;
  font-weight: 700;
  -webkit-text-stroke: 2px #fff;
  color: #262626;
  margin: 20px 0px;
}
.login-form .login-content .desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin: 5px 0px;
}
.login-form .login-content .desc span {
  font-weight: 700;
  font-size: 18px;
  color: #13aac7;
}
.login-form .login-content .bluebug {
  font-size: 12px;
}
.login-form .login-content .bluebug a {
  color: #13aac7;
  font-weight: 500;
}
.login-form .form {
  width: 40%;
  z-index: 2;
  padding: 30px;
  background-color: #13aac7;
  border-radius: 30px;
}
.login-form .form form {
  padding: 20px 0px;
}
.login-form .form form label {
  font-size: 18px;
  cursor: pointer;
}
.login-form .form form .input {
  margin: 5px 0px 10px 0px;
  padding: 5px;
  font-size: 16px;
  width: 100%;
  border-radius: 10px;
  border: 2px solid #fff;
  outline: 2px solid #fff;
}
.login-form .form form #showPassword {
  padding: 20px;
  margin: 10px 30px 10px 0px;
}
.login-form .form form button {
  padding: 10px 30px;
  margin-top: 20px;
  border-radius: 10px;
  font-size: 16px;
  background-color: #fff;
  font-weight: 700;
  border: 2px solid #262626;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.login-form .form form button:hover {
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
}

.page-balance-sheet .sub-heading {
  font-size: 16px;
  margin: 5px 0px 15px 0px;
}
.page-balance-sheet .select-filter {
  display: flex;
  justify-content: start;
  gap: 40px;
}
.page-balance-sheet .select-filter input,
.page-balance-sheet .select-filter select {
  width: 40%;
  padding: 7px 10px;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: #353537;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
}


