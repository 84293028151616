.sidebar {


  .logo {
    width: 100%;


    img {
      width: 100%;
      border-radius: 10px;
    }
  }

  nav {
    width: 100%;
    margin-top: 30px;

    ul {
      li {
        // padding: 10px 15px;


        a {
          font-size: 16px;
          font-weight: 500;
          color: $white;
          // padding: 10px 0px;
          line-height: 3;
          transition: 0.3s ease-in-out;
          padding: 10px 15px;


        }

        &:hover,
        .active {
          a {
            padding: 10px 15px;

            background-color: $hover;
            border-radius: 10px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), 4px 0px 4px rgba(0, 0, 0, 0.1);

          }
        }
      }
    }
  }

  .logout {
    button {
      padding: 10px 20px;
      font-size: 14px;
      font-weight: 600;
      border-radius: 10px;
      background-color: $secondaryColor;
      border: 2px solid $secondaryColor;
      color: $white;
      font-family: $Mont;
      transition: 0.3s ease-in-out;

      &:hover {
        background-color: transparent;
        color: $secondaryColor;
      }
    }
  }
}


a.active-link {
  padding: 10px 25px;

  background-color: $hover;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), 4px 0px 4px rgba(0, 0, 0, 0.1);
}