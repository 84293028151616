.login-form {
  height: calc(100vh - 20px);
  width: calc(100vw - 20px);
  // margin: 0px 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0px 40px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.925);
    /* Dark overlay with 50% opacity */
    z-index: 1;
    border-radius: 50px;
    /* Ensure the overlay is below the content */
  }

  .login-content {
    width: 55%;
    z-index: 2;

    .login-heading {
      font-size: 52px;
      font-weight: 700;
      -webkit-text-stroke: 2px $white;
      color: $black;
      margin: 20px 0px;
    }

    .desc {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      margin: 5px 0px;

      span {
        font-weight: 700;
        font-size: 18px;
        color: $secondaryColor;
      }
    }

    .bluebug {
      font-size: 12px;

      a {
        color: $secondaryColor;
        font-weight: 500;
      }
    }

  }

  .form {
    width: 40%;
    z-index: 2;
    padding: 30px;
    background-color: $secondaryColor;
    border-radius: 30px;

    form {
      padding: 20px 0px;

      label {
        font-size: 18px;
        cursor: pointer;

      }

      .input {
        margin: 5px 0px 10px 0px;
        padding: 5px;
        font-size: 16px;
        width: 100%;
        border-radius: 10px;
        border: 2px solid $white;
        outline: 2px solid $white;
      }

      #showPassword {
        padding: 20px;
        margin: 10px 30px 10px 0px;

      }

      button {
        padding: 10px 30px;
        margin-top: 20px;
        border-radius: 10px;
        font-size: 16px;
        background-color: $white;
        font-weight: 700;
        border: 2px solid $black;
        transition: 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          border: 2px solid $white;
          background-color: transparent;
          color: $white;

        }
      }

    }
  }
}