//Fonts
$Inter: "Inter", sans-serif;
$Mont: "Montserrat Alternates", sans-serif;




//website  Colors variable
$primaryColor: #35353d;
$acent: #2602f5;
$secondaryColor: #13aac7;
$black: #262626;
$t-color: #595959;
$white: #fff;
$bg: #242529;
$hover: #353537;
$abs: #dcdcdc;
$p-black: #232426;
$red: #faa483;