.btn-primary {
  background-color: $primaryColor;
  display: inline-block;
  color: $white;
  padding: 10px 30px;
  font-family: $Mont;
  font-weight: 400;
  border: none;


}

.btn-secondary {
  background-color: #7F8287;
  display: inline-block;
  color: $white;
  padding: 10px 30px;
  font-family: $Mont;
  font-weight: 400;
  border: none;


}