.dashboard {
  height: 100%;

  .main-content {
    display: flex;
    justify-content: space-between;
    height: 100%;

    // box flex structure 
    .dashboard-two-box {
      height: 100%;
      width: 48%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .dashboard-two-box-inner {
        display: flex;
        justify-content: space-between;

        .item {
          width: 42%;
          background-color: $hover;

          // ticketing-section start
          .ticket-prices {


            P {
              font-size: 18px;
              margin-bottom: 15px;
            }

            button {
              font-size: 14px;
              padding: 10px 20px;
              border-radius: 20px;
              background-color: $abs;
              border: 2px solid $hover;
              font-weight: 500;
              transition: 0.3s ease-in-out;

              &:hover {
                color: $abs;
                border: 2px solid $abs;
                background-color: transparent;
                scale: 1.03;

              }
            }
          }

          // ticketing section end 

          // warning section start 
          .warning-box {
            height: 150px;

            h3 {
              // color: $secondaryColor;
            }

            .warning-block {
              background-color: brown;
              margin: 5px 0px;
              padding: 5px;
              border-radius: 10px;

              margin-right: 10px;
            }
          }

          // warning section end 

        }

      }

      // box flex structure end 
      // tickiting section 
      .ticketing-form {
        background-color: $secondaryColor;

        h2 {
          color: $black;
        }

        form {
          width: 100%;

          .form-group {
            // overflow: hidden;
            width: 100%;
            display: flex;
            margin: 10px 0px;
            gap: 20px;

            div {
              width: 100%;

              label {
                display: block;
                font-size: 16px;
                margin-bottom: 5px;
                font-family: $Inter;
                color: $black;
              }

              input {
                width: 100%;
                padding: 7px 10px;
                border-radius: 10px;
                outline: none;
                border: none;
                background-color: $hover;
                color: $white;
                font-size: 16px;
                box-sizing: border-box;
              }

              select {
                width: 100%;
                padding: 7px 10px;
                border-radius: 10px;
                outline: none;
                border: none;
                background-color: $hover;
                color: $white;
                font-size: 16px;
                box-sizing: border-box;
              }
            }
          }

          .total-price {
            font-size: 18px;
            color: $white;
            font-weight: 600;
            margin: 15px 0px;
          }

          button {
            font-size: 16px;
            font-weight: 500;
            padding: 10px 30px;
            border-radius: 20px;
            color: $black;
            background-color: $abs;
            border: 2px solid $hover;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            letter-spacing: 1px;

            &:hover {
              background-color: transparent;
              color: $hover;
              scale: 1.03;
            }
          }



        }

        .available {
          font-size: 16px;
          font-weight: 400;
          text-align: right;
          color: $black;
        }
      }

      // ticketing section end 


      .two-box-right {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 30px;

        // active session start 
        .active-sessions {
          height: 48%;
          background-color: $hover;

        }

        // active sectio end 

        .sessions-ending-soon {
          height: 48%;
          background-color: $hover;
        }

      }


    }
  }
}