@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');




* {
  margin: 0;
  padding: 0px;

}

body {
  background-color: $bg;
}



body,
p,
a {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: $Inter;
  color: $white;

}


h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6 {
  font-family: $Mont;
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: $white;
}


p {
  font-family: $Inter;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: $white;
}

ul,
li,
a {
  margin: 0px;
  padding: 0px;
  list-style: none;
  text-decoration: none;
  font-family: $Mont;
}